.drafts {
   background-color: #CECDCD !important;
   width: 64px !important;
   color: #404040 !important;
   margin-right: 6px !important;
   font-size: 10px !important;
   font-family: "SF-Pro-Rounded";
   box-shadow: none !important;
   text-transform: capitalize;
}

.modal-top-title .no-dis path {
   fill: #404040 !important;
}

.mar.cityflag {
   height: auto;
   width: 70px;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: flex-start;
}

.mar.cityflag:not(:first-child) {
   margin-left: 9.5px;
}

.modal-icon-name {
   display: flex;
   flex-direction: row;
   align-items: center;
   justify-content: flex-start;
}

.modal-name-icon {
   margin-left: 5px;
   font-family: "SF-Pro-Rounded";
   font-size: 10px;

}

.pad35 {
   padding-left: 44px !important;
}

.abs-icon {
   position: absolute;
   left: 15.5px;
}

.cal {}

.input-holder {
   position: relative;
   transition: 0.3s;
}

.trf .input-holder input {
   height: 44px;
   display: flex;
   padding-top: 6px;
   align-items: center;
}

.input-holder input::placeholder {
   color: #DDDDDD;
   font-size: 12px;
   font-family: "SF-Pro-Rounded";
}

.input-holder img {
   position: absolute;
   right: 0px;
   bottom: 12px;
}

.fill-norm path {
   fill: #404040;
}

.icons-ol {
   height: 44px;
}

.holder-option {
   width: 33.3%;
   height: 100%;
   display: flex;
   align-items: center;
   cursor: pointer;
   justify-content: center;
}

.holder-option svg {
   position: static !important;
}

.option-desc {
   margin-left: 9px;
   background-color: #404040;
   border-radius: 5px;
   padding-left: 10px;
   padding-right: 10px;
   padding-bottom: 4px;
   padding-top: 4px;
   font-size: 10px;
   font-family: "SF-Pro-Rounded";
   color: #FFFFFF;
   max-height: 22px;
}

.holder-option svg:not(.fill-norm) path {
   fill: #5d5d5d;
}

.modal-footer {
   width: 100%;
   margin-top: 50px;
   display: flex;
   justify-content: center;
   align-items: center;
}

.modal-button-transfer {
   font-size: 10px;
   font-family: "SF-Pro-Rounded-med";
   background-color: #5674F0;
   border-radius: 10px;
   cursor: pointer;
   color: #FFFFFF;
   padding: 14px 20px;
}

.modal-button-transfer.disable {
   background-color: #AFBFFD;
}

.modal-icons.trf {
   align-items: flex-start !important;
}

.trf .input-section {
   margin-top: 0px !important;
}

input {
   z-index: 888;
}

.pl,
img {
   cursor: pointer;
}

.amoun input {
   font-family: "SF-Pro-Rounded-med" !important;
}

.data-country {
   position: absolute;
   left: 15px;
   bottom: 11px !important;
}

img.data-country {
   bottom: 8px !important;
   border-radius: 3px;
   object-fit: cover;
}

.pad48 input {
   padding-left: 47px !important;
}

input.cal#caland {
   right: 0 !important;
   left: initial !important;
   width: 20%;
   transform: none !important;
   position: absolute;
   padding: 0px !important;
}

input.cal:focus {}

svg {
   transition: 0.3s;

}

svg path {
   transition: 0.3s;
}

svg.red-bord path {
   fill: #EA4343;
}

.lang-modal {
   box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 20px;
   border: rgba(221, 221, 221, 0.52) 0.5px solid;
}

input#session-date::-webkit-calendar-picker-indicator {
   display: block;
   top: 0;
   left: 0;
   background: #0000;
   position: absolute;
   transform: scale(12)
}

input::-webkit-date-and-time-value {
   text-align: right;
}

.spin-cont {
   display: flex;
   align-items: center;
   justify-content: center;
   background-color: #f5f3f3 !important;
}

.spin-cont.no-tran {
   display: flex;
   min-width: 100%;
   align-items: center;
   justify-content: center;
   background-color: transparent !important;
}

.spin-cont2 {
   display: flex;
   align-items: center;
   justify-content: center;
   background-color: #f5f3f3 !important;
   position: absolute;
   top: 50%;
   left: 50%;
}

.spin-cont2.no-tran {
   display: flex;
   min-width: 100%;
   align-items: center;
   justify-content: center;
   background-color: transparent !important;
}

.contact-method {
   display: flex;
   align-items: center;
   position: absolute;
   top: 19px;
   right: 25.5px;
}

.contact-method svg {
   position: static !important;
   cursor: pointer;
}

.contact-method svg:not(:last-child) {
   margin-right: 10px;
}

.plus-minus {
   position: absolute;
   top: 6px;
   right: -3px;
}

.second-text {
   margin-left: 9px;
   font-size: 10px;
   color: #CECDCD;
   font-family: "SF-PRO-Rounded_light";
}

.languages-page {
   padding: 20px;
   background: #f8f9fa;
   font-family: Arial, sans-serif;
}

.lang-top {
   display: flex;
   justify-content: space-between;
   align-items: center;
   margin-bottom: 20px;
}

.filters {
   display: flex;
   gap: 10px;
   width: -webkit-fill-available;
}

.filter-input {
   padding: 8px;
   border: 1px solid #ccc;
   border-radius: 5px;
   width: 150px;
}

.employee-table {
   width: 100%;
   border-collapse: collapse;
   background: #fff;
   box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
   margin: 3px;
   padding: 3px;
}

.employee-table th,
.employee-table td {
   padding: 10px;
   text-align: left;
   border-bottom: 1px solid #ddd;
}

.employee-row {
   cursor: pointer;
   transition: background 0.2s;
}

.employee-row:hover {
   background: #f1f1f1;
}

.employee-photo {
   width: 40px;
   height: 40px;
   border-radius: 50%;
   object-fit: cover;
}

.expand-icon {
   text-align: center;
   font-weight: bold;
}

/* CSS for the expanded row */
.details-row {
   background-color: #f5f5f5;
   /* Light background for the expanded row */
   transition: all 0.3s ease;
   /* Smooth transition for expansion */
}

/* Make each field appear like a square (card-like) */
.details-content {
   display: grid;
   /* Use grid for layout */
   grid-template-columns: repeat(2, 1fr);
   /* Create two columns for each field */
   gap: 15px;
   /* Space between the fields */
   padding: 20px;
   /* Padding around the content */
   font-size: 14px;
   /* Font size for details */
   color: #333;
   /* Text color */
   border-top: 1px solid #ddd;
   /* Light border at the top of the expanded section */
   background-color: #fff;
   /* White background for the details content */
   border-radius: 8px;
   /* Rounded corners for the content */
   box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
   /* Light shadow for a card effect */
}

/* Style individual fields as squares */
.details-content p {
   background-color: #f8f8f8;
   /* Light background for each field */
   border: 1px solid #ddd;
   /* Border around each field */
   border-radius: 8px;
   /* Rounded corners */
   padding: 15px;
   /* Padding inside each field */
   text-align: center;
   /* Center text inside the field */
   font-weight: bold;
   /* Bold text */
   box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
   /* Light shadow for each square */
}

/* Optional: Hover effect for each square */
.details-content p:hover {
   background-color: #e0e0e0;
   /* Slightly darker background on hover */
   transform: translateY(-2px);
   /* Lift the square on hover */
   transition: all 0.2s ease;
   /* Smooth hover transition */
}

/* Optional: Add a hover effect for the whole expanded row */
.details-row:hover {
   background-color: #f0f0f0;
   /* Slightly darker background when hovered */
}

/* Optional: Animation for smooth row expansion */
.employee-row {
   transition: all 0.3s ease-in-out;
}

/* You can add more specific styles based on the structure of your page */
.avatar-column {
   width: 60px;
   /* Adjust as needed */
   text-align: center;
}

.employee-photo {
   width: 40px;
   height: 40px;
   border-radius: 50%;
   object-fit: cover;
}

/* Chat Filters Section */
.monitoring-chat-filters {
   display: flex;
   gap: 10px;
   flex-wrap: nowrap;
   padding: 10px 0;
   height: 67px;
   width: 100%;
   overflow-x: scroll;
   overflow-y: hidden;
}

.monitoring-chat-item {
   width: fit-content;
   display: flex;
   align-items: center;
   background-color: #F4F5F5;
   padding: 8px 12px;
   border-radius: 8px;
   cursor: pointer;
   transition: background-color 0.3s, transform 0.2s;
   box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.monitoring-chat-item:hover {
   background-color: #e0e0e0;
   transform: scale(1.05);
}

.monitoring-chat-item.selected {
   background-color: #4CAF50;
   color: white;
   font-weight: bold;
}

.monitoring-chat-icon {
   width: 32px;
   height: 32px;
   border-radius: 50%;
   overflow: hidden;
   margin-right: 8px;
}

.monitoring-chat-photo {
   width: 100%;
   height: 100%;
   object-fit: cover;
   border-radius: 50%;
}

.monitoring-chat-info {
   flex-grow: 1;
}

.monitoring-chat-name {
   font-size: 14px;
   font-weight: 600;
}

/* Period Filter Input */
.monitoring-filter-input {
   padding: 8px;
   border: 1px solid #ccc;
   border-radius: 5px;
   font-size: 14px;
   width: 200px;
}

/* Responsive Styles */
@media (max-width: 768px) {
   .monitoring-chat-filters {
      flex-direction: column;
   }

   .monitoring-chat-item {
      width: 100%;
   }
}

.monitoring-status_info_name {
   font-size: 12px;
   text-wrap-mode: nowrap;
}